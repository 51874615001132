<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Code</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Code"
              v-model="filter.code"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Name</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Name"
              v-model="filter.name"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-md" @click="getScopes(true)">
            <i class="flaticon2-search-1"></i> Filter
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="d-flex my-3 justify-content-start">
        <button class="btn btn-success btn-md" @click="showAddModal">
          <i class="flaticon-add"></i> Add Scope
        </button>
      </div>

      <div class="card-body p-0">
        <complete-table :loading="loading" :data="scopes">
          <template #header>
            <th style="min-width: 250px" class="pl-7">
              <span class="text-dark-75">Code</span>
            </th>
            <th style="min-width: 100px">Name</th>
            <th style="min-width: 101px">Action</th>
          </template>

          <template #defaultBody="{ item: scope, i }">
            <tr v-bind:key="i">
              <td class="pl-0 ">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ scope.code }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ scope.name }}
                </span>
              </td>

              <td class="pr-0">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="showEditModal(scope)"
                >
                  <i class="flaticon-edit"></i>
                </a>

                <a
                  class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                  @click="showDeleteModal(scope)"
                >
                  <i class="flaticon-delete"></i>
                </a>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <b-modal ref="add-scope" hide-footer title="Form Add Scope">
      <div class="d-block text-center">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Code *</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Code"
              v-model="form.code"
            ></b-form-input>
            <p v-if="error.code" class="text-danger">
              Code Can't Be Empty
            </p>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Scope *</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Scope"
              v-model="form.scope"
            ></b-form-input>
            <p v-if="error.scope" class="text-danger">
              Scope Can't Be Empty
            </p>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Manager *</label>

            <b-form-select
              v-model="form.manager"
              :options="formattedPicOptions"
              size="lg"
            ></b-form-select>
            <p v-if="error.manager" class="text-danger">
              Manager Can't Be Empty
            </p>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Scope Category *</label>

            <b-form-select
              v-model="form.scope_category"
              :options="formattedScopeOptions"
              size="lg"
            ></b-form-select>
            <p v-if="error.scope_category" class="text-danger">
              Kategori Scope Can't Be Empty
            </p>
          </div>
        </div>
      </div>

      <div class="d-flex mt-2">
        <b-button
          class="btn btn-success mr-2"
          block
          :disabled="loading"
          @click="addScope"
          >Save</b-button
        >

        <b-button
          class="btn btn-secondary mt-0"
          block
          :disabled="loading"
          @click="closeAddModal"
          >Cancel</b-button
        >
      </div>
    </b-modal>

    <b-modal ref="edit-scope" hide-footer title="Form Ubah Scope">
      <div class="d-block text-center">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Code *</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Code"
              disabled
              v-model="form.code"
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Scope *</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Scope"
              v-model="form.scope"
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Manager *</label>

            <b-form-select
              v-model="form.manager"
              :options="formattedPicOptions"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Scope Category *</label>

            <b-form-select
              v-model="form.scope_category"
              :options="formattedScopeOptions"
              size="lg"
            ></b-form-select>
          </div>
        </div>
      </div>

      <div class="d-flex mt-2">
        <b-button
          class="btn btn-success mr-2"
          block
          :disabled="loading"
          @click="updateScope"
        >
          Save
        </b-button>

        <b-button
          class="btn btn-secondary mt-0"
          block
          :disabled="loading"
          @click="closeEditModal"
        >
          Cancel
        </b-button>
      </div>
    </b-modal>

    <b-modal ref="delete-scope" hide-footer title="Hapus Scope">
      <div class="d-block text-center mb-5 pb-5 pt-5">
       Are You Sure Want To Delete Scope ?
      </div>

      <div class="d-flex mt-5">
        <b-button
          class="btn btn-success mr-2"
          block
          :disabled="loading"
          @click="deleteScope"
          >Delete</b-button
        >

        <b-button
          class="btn btn-secondary mt-0"
          block
          :disabled="loading"
          @click="closeDeleteModal"
          >Cancel</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import { GET_PIC } from "../../../core/services/store/pic.module";
import { GET_SCOPE_CATEGORY } from "../../../core/services/store/scope-category.module";
import {
  DELETE_SCOPE,
  GET_SCOPES,
  SAVE_SCOPE,
  UPDATE_SCOPE
} from "../../../core/services/store/scope.module";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";

export default {
  name: "scope",
  components: { CompleteTable },
  data() {
    return {
      filter: {
        initial: null,
        name: null
      },
      data: {
        scopes: [
          {
            code: "HMI",
            scope: "HMI",
            manager: 1,
            scope_category: 1
          }
        ]
      },
      form: {
        code: null,
        scope: null,
        manager: null,
        scope_category: null
      },
      error: {
        code: null,
        scope: null,
        manager: null,
        scope_category: null
      }
    };
  },
  computed: {
    ...mapState({
      pics: state => state.pic.pics?.data,
      loading: state => state.scope.loading,
      scopeCategories: state => state.scopeCategory.scopeCategories?.data,
      scopes: state => state.scope.scopes?.data
    }),

    formattedScopeOptions() {
      let scopes = [];

      if (arrayMoreThanOne(this.scopeCategories)) {
        scopes = this.scopeCategories.map(scope => {
          return {
            value: scope.code,
            text: scope.name
          };
        });
      }

      return [{ value: null, text: "Choose Scope Category" }, ...scopes];
    },

    formattedPicOptions() {
      let pics = [];

      if (arrayMoreThanOne(this.pics)) {
        pics = this.pics
            .filter((pic) => {
              return pic.position_mso ? (pic.position_mso.toLowerCase() === 'manager') : false;
            })
            .map(pic => {
              return {
                value: pic.code,
                text: pic.name
              };
            });
      }

      return [{ value: null, text: "Choose PIC" }, ...pics];
    }
  },
  methods: {
    showAddModal() {
      this.form = {
        code: null,
        scope: null,
        manager: null,
        scope_category: null
      };

      this.$refs["add-scope"].show();
    },

    closeAddModal() {
      this.$refs["add-scope"].hide();
    },

    addScope() {
      const manager = this.formattedPicOptions.find(
        pic => pic.value === this.form.manager
      );

      const scopeCategory = this.formattedScopeOptions.find(
        scope => scope.value === this.form.scope_category
      );

      const clearForm = {
        ...this.form,
        name: this.form.scope,
        manager: {
          code: manager.value,
          name: manager.text
        },
        scope_category: {
          code: scopeCategory.value,
          name: scopeCategory.text
        }
      };

      console.log(clearForm);
      if (
        clearForm.name &&
        clearForm.manager.code &&
        clearForm.manager.name &&
        clearForm.scope_category.code &&
        clearForm.scope_category.name
      ) {
        this.$store.dispatch(SAVE_SCOPE, clearForm).then(() => {
          this.closeAddModal();
        });
      }
    },

    updateScope() {
      const manager = this.formattedPicOptions.find(
        pic => pic.value === this.form.manager
      );

      const scopeCategory = this.formattedScopeOptions.find(
        scope => scope.value === this.form.scope_category
      );

      const clearForm = {
        ...this.form,
        name: this.form.scope,
        manager: {
          code: manager.value,
          name: manager.text
        },
        scope_category: {
          code: scopeCategory.value,
          name: scopeCategory.text
        }
      };

      this.$store.dispatch(UPDATE_SCOPE, clearForm).then(() => {
        this.closeEditModal();
      });
    },

    showEditModal(scope) {
      Object.assign(this.form, {
        code: scope.code,
        scope: scope.name,
        manager: scope.manager?.code,
        scope_category: scope.scope_category?.code
      });

      this.$refs["edit-scope"].show();
    },

    closeEditModal() {
      this.$refs["edit-scope"].hide();
    },

    showDeleteModal(scope) {
      Object.assign(this.form, {
        code: scope.code,
        scope: scope.scope,
        manager: scope.manager,
        scope_category: scope.scope_category
      });

      this.$refs["delete-scope"].show();
    },

    closeDeleteModal() {
      this.$refs["delete-scope"].hide();
    },

    deleteScope() {
      this.$store
        .dispatch(DELETE_SCOPE, {
          code: this.form.code
        })
        .then(() => {
          this.closeDeleteModal();
        });
    },

    getScopeCategories() {
      if (!arrayMoreThanOne(this.scopes)) {
        this.$store.dispatch(GET_SCOPE_CATEGORY, this.filter);
      }
    },

    getPics() {
      if (!arrayMoreThanOne(this.pics)) {
        this.$store.dispatch(GET_PIC, {});
      }
    },

    getScopes(filter = false) {
      if (!arrayMoreThanOne(this.scopes) || filter) {
        this.$store.dispatch(GET_SCOPES, {
          params: this.filter
        });
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Scope" }]);

    this.getScopeCategories();

    this.getPics();

    this.getScopes();
  }
};
</script>

<style></style>
